import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import styles from '../styles/modules/ProfilePicture.module.scss'

export const ProfilePicture = () => {
	const { user } = useAuth0()
	if (!user) return null
	if (!user.picture)
		return (
			<div className={styles.profilePicture}>{user.name && user.name[0]}</div>
		)
	return (
		<>
			<img className={styles.profilePicture} src={user.picture} alt="" />
		</>
	)
}
