import { faPaperPlane, faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Container, Form, InputGroup, Navbar } from 'react-bootstrap'
import styles from '../styles/modules/Footer.module.scss'
import { AudioRec } from './AudioRec'

interface FooterProps {
	onPromptSend: (prompt: string | Blob) => void
}

export const Footer = (props: FooterProps) => {
	const { onPromptSend } = props
	const [prompt, setPrompt] = useState<string>('')
	const sendPrompt = () => {
		onPromptSend(prompt)
		setPrompt('')
	}

	return (
		<Navbar className={styles.footerContainer} fixed="bottom" bg="gray">
			<Container className={styles.footer}>
				<Button className={`${styles.buttonNext} rounded`} variant="purple">
					<FontAwesomeIcon icon={faEllipsis} />
				</Button>
				<InputGroup className={styles.inputContainer}>
					<Form.Control
						className={styles.input}
						as="textarea"
						rows={1}
						placeholder="Ask me anything..."
						value={prompt}
						onChange={(e) => setPrompt(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								sendPrompt()
								e.preventDefault()
							}
						}}
					/>
					{prompt.length > 0 ? (
						<Button
							className={styles.buttonSend}
							variant="transparent"
							onClick={() => {
								sendPrompt()
							}}
						>
							<FontAwesomeIcon icon={faPaperPlane} />
						</Button>
					) : (
						<AudioRec onSend={onPromptSend} />
					)}
				</InputGroup>
			</Container>
		</Navbar>
	)
}
