import styles from '../styles/modules/Chat.module.scss'
import { Message } from '../types/Message'
import { MessageSender } from '../enums/MessageSender'
import { Alert, Container } from 'react-bootstrap'
import { MessageState } from '../enums/MessageState'
import { ThreeDots } from 'react-loader-spinner'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'

interface ChatProps {
	messages: Message[]
	isAwaitingReply: boolean
}

export const Chat = (props: ChatProps) => {
	const { messages, isAwaitingReply } = props
	return (
		<Container className={styles.chat} id="chat">
			{/* <img
				src="@/assets/images/logo.png"
				alt="OppiAI Logo"
				className={styles.logo}
			/> */}
			{messages
				.filter((m) => m.state === MessageState.SENT)
				.map((message, i) => (
					<Alert
						key={i}
						className={classNames(styles.message, {
							[styles.messageUser]:
								message.sender === MessageSender.USER,
							[styles.messageBot]: message.sender === MessageSender.BOT,
						})}
						variant={
							message.sender === MessageSender.USER
								? 'purple'
								: 'secondary'
						}
					>
						{!!message.text ? (
							<ReactMarkdown>{message.text}</ReactMarkdown>
						) : (
							!!message.audio && (
								<audio
									controls={true}
									src={URL.createObjectURL(message.audio)}
								/>
							)
						)}
					</Alert>
				))}
			{isAwaitingReply && (
				<ThreeDots
					height="40"
					width="40"
					radius="50"
					color="#413dc4"
					ariaLabel="three-dots-loading"
					visible={true}
				/>
			)}
		</Container>
	)
}
