export const blobToBase64 = (file: Blob) =>
	new Promise<string>((resolve, reject) => {
		const fileReader = new FileReader()
		fileReader.onload = () => {
			const base64String = `${fileReader.result}`.split(',')[1]
			resolve(base64String)
		}
		fileReader.onerror = (error) => {
			reject(error)
		}
		fileReader.readAsDataURL(file)
	})
