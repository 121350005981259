import { Navbar, Container, Nav, Button, Offcanvas } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faQuestion } from '@fortawesome/free-solid-svg-icons'
import styles from '../styles/modules/Header.module.scss'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { ProfilePicture } from './ProfilePicture'

type HeaderProps = {
	onNewChat: () => void
	onHelp: () => void
}

export const Header = (props: HeaderProps) => {
	const { onNewChat, onHelp } = props

	const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0()

	const authButton = () => {
		return !isAuthenticated ? (
			<Button
				onClick={() =>
					loginWithRedirect({
						appState: {
							returnTo: window.location.origin,
						},
					})
				}
				variant="purple"
			>
				Login
			</Button>
		) : (
			<Button
				onClick={() =>
					logout({
						logoutParams: {
							returnTo: window.location.origin,
						},
					})
				}
				variant="purple"
			>
				Logout
			</Button>
		)
	}

	return (
		<>
			<Navbar
				className={styles.headerContainer}
				fixed="top"
				expand="lg"
				bg="gray"
			>
				<Container className={styles.header}>
					<Navbar.Toggle aria-controls="navbar" />
					<Navbar.Offcanvas className={styles.navbar}>
						<Offcanvas.Header closeButton>
							<Offcanvas.Title className={styles.title}>
								Oppi Chat
							</Offcanvas.Title>
						</Offcanvas.Header>
						<Offcanvas.Body>
							<Nav className={styles.nav}>
								<ProfilePicture />
								{/* <InstallButton /> */}
								{authButton()}
							</Nav>
						</Offcanvas.Body>
					</Navbar.Offcanvas>
					<h1>
						{!isAuthenticated && !user
							? `Oppi Chat/${process.env.REACT_APP_VERSION}`
							: `Hello ${user?.nickname}!`}
					</h1>
					<div>
						<Button
							variant="transparent"
							onClick={() => {
								onNewChat()
							}}
						>
							<FontAwesomeIcon icon={faPlus} />
						</Button>
						<Button
							variant="transparent"
							onClick={() => {
								onHelp()
							}}
						>
							<FontAwesomeIcon icon={faQuestion} />
						</Button>
					</div>
				</Container>
			</Navbar>
		</>
	)
}
