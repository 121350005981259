import axios from 'axios'
import { ApiMessageType, ApiRequestType } from '../types/Api'

// const baseUrl = process.env.NODE_ENV === 'production' ? 'https://page.oppi.ai' : ''
const baseUrl = 'https://page.oppi.ai'

export const useChatApi = (idToken: string) => {
	const callGetAnswerQuery = (req: ApiRequestType, user: string) => {
		return axios.post<ApiMessageType>(
			`${baseUrl}/v1/chat`,
			{
				user,
				session: 'test_session',
				...req
			},
			{
				headers: {
					'Content-Type': 'application/json',
					// Authorization: `Bearer ${idToken}`,
				},
			}
		)
	}

	return { callGetAnswerQuery }
}
