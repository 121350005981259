import { useEffect, useState } from 'react'
import { useAudioRecorder } from 'react-audio-voice-recorder'
import { AudioRecProps } from '../components/AudioRec'

export const useAudioRec = (props: AudioRecProps) => {
	const {
		startRecording,
		stopRecording,
		togglePauseResume,
		recordingBlob,
		isRecording,
		isPaused,
	} = useAudioRecorder()
	const [isSending, setIsSending] = useState(false)
	const [blobToSend, setBlobToSend] = useState<Blob | undefined>(undefined)

	useEffect(() => {
		if (isSending && blobToSend) {
			setIsSending(false)
			console.log('REC', blobToSend)
			props.onSend(blobToSend)
		}
	}, [isSending, blobToSend, props])

	useEffect(() => {
		setBlobToSend(recordingBlob)
	}, [recordingBlob])

	const start = () => {
		startRecording()
		setBlobToSend(undefined)
	}

	const send = () => {
		stopRecording()
		setIsSending(true)
	}

	const discard = () => {
		stopRecording()
		setBlobToSend(undefined)
	}

	return { send, discard, start, isPaused, isRecording, isSending }
}
