import { Chat } from './components/Chat'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import styles from './styles/modules/App.module.scss'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useChatUI } from './hooks/useChatUI'
import { createContext, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
// var jwt = require('jsonwebtoken')

function App() {
	const [idToken, setIdToken] = useState('')

	const { messages, isSendingPrompt, createNewChat, sendPrompt, onHelp } =
		useChatUI(idToken)

	const { user, getIdTokenClaims } = useAuth0()

	const TokenContext = createContext('')

	useEffect(() => {
		if (!user) return

		getIdTokenClaims().then((claims) => {
			claims && setIdToken(claims.__raw)
		})
	}, [user, getIdTokenClaims])

	return (
		<div className={styles.app} id="app">
			<TokenContext.Provider value={idToken}>
				<Header onNewChat={createNewChat} onHelp={onHelp} />
				<BrowserRouter>
					<Routes>
						<Route
							index
							element={
								<Chat
									messages={messages}
									isAwaitingReply={isSendingPrompt}
								/>
							}
						/>
					</Routes>
				</BrowserRouter>
				<Footer
					onPromptSend={(prompt) => {
						sendPrompt(prompt)
					}}
				/>
			</TokenContext.Provider>
		</div>
	)
}

export default App
