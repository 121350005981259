import {
	faMicrophone,
	faXmark,
	faPaperPlane,
	faCircleNotch,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAudioRec } from '../hooks/useAudioRec'
import styles from '../styles/modules/Footer.module.scss'
import { Button } from 'react-bootstrap'

export type AudioRecProps = {
	onSend: (blob: Blob) => void
}

export const AudioRec = (props: AudioRecProps) => {
	const { start, send, discard, isRecording, isPaused, isSending } =
		useAudioRec(props)

	return (
		<div>
			{isSending ? (
				<div>
					<FontAwesomeIcon icon={faCircleNotch} spin />
				</div>
			) : !isRecording && !isPaused ? (
				<Button onClick={start} className={styles.buttonSend}>
					<FontAwesomeIcon icon={faMicrophone} />
				</Button>
			) : (
				isRecording &&
				!isPaused && (
					<>
						<Button onClick={send} className={styles.buttonSend}>
							<FontAwesomeIcon icon={faPaperPlane} />
						</Button>
						<Button onClick={discard} className={styles.buttonSend}>
							<FontAwesomeIcon icon={faXmark} />
						</Button>
					</>
				)
			)}
		</div>
	)
}
