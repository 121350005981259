import { useState } from 'react'
import { Message, introMessage } from '../types/Message'
import { MessageSender } from '../enums/MessageSender'
import { MessageState } from '../enums/MessageState'
import { useChatApi } from './useChatApi'
import { useAuth0 } from '@auth0/auth0-react'
import { ApiRequestType } from '../types/Api'
import { blobToBase64 } from '../utils/helpers'

export const useChatUI = (idToken: string) => {
	const [messages, setMessages] = useState([introMessage])
	const [isSendingPrompt, setIsSendingPrompt] = useState(false)
	const { callGetAnswerQuery } = useChatApi(idToken)
	const { user } = useAuth0()

	const scrollToTop = () => {
		window.scrollTo(0, 0)
	}

	const scrollToBottom = () => {
		window.scrollTo(0, document.body.scrollHeight)
	}

	const createNewChat = () => {
		scrollToTop()
		setMessages([])
		sendPrompt('!new')
	}

	const onHelp = () => {
		sendPrompt('!help')
	}

	const sendPrompt = async (prompt: string | Blob) => {
		scrollToBottom()
		let apiReq: ApiRequestType
		let message: Partial<Message>
		if (typeof prompt === 'string') {
			apiReq = message = {
				text: prompt,
			}
		} else if (prompt instanceof Blob) {
			const b64 = await blobToBase64(prompt)
			console.log('B64', b64)
			apiReq = {
				audio: b64,
				type: prompt.type,
			}
			message = { audio: prompt }
		} else {
			console.error('Invalid prompt type')
			return
		}
		setMessages((m) => [
			...m,
			{
				sender: MessageSender.USER,
				state: MessageState.SENT,
				...message,
			},
		])
		setIsSendingPrompt(true)
		callGetAnswerQuery(apiReq, user?.nickname || 'anonymous')
			.then(({ data }) => {
				setMessages((m) => [
					...m,
					{
						text: data.text,
						sender: MessageSender.BOT,
						state: MessageState.SENT,
					},
				])
			})
			.catch((e) => {
				alert('ERROR:' + e)
			})
			.finally(() => {
				setIsSendingPrompt(false)
				scrollToBottom()
			})
	}

	return { createNewChat, messages, isSendingPrompt, sendPrompt, onHelp }
}
