import { User, useAuth0 } from '@auth0/auth0-react'
import { Role } from 'auth0'
import axios from 'axios'

export const AUTH0_DOMAIN = 'oppi-chat.eu.auth0.com'
export const AUTH0_CLIENT_ID = '6poizsejnaAyB05j9qIRkpuDrsjlKMnL'

export const useAuth = () => {
	const getAccessToken = async (): Promise<string> => {
		let accessToken: string = ''
		await axios
			.post(`https://oppi-chat.eu.auth0.com/oauth/token`, {
				client_id: 'E7BlIKYOiBCR8hrVhFFKSRELUms0xyiE',
				client_secret:
					'LQkSlFGO1PLqhCEzHQtAviT-PWNJBY5m4QB8Xyg6yNkG-zUx9xk-873qy2Oo3OyQ',
				audience: 'https://oppi-chat.eu.auth0.com/api/v2/',
				grant_type: 'client_credentials',
			})
			.then((res) => {
				accessToken = res.data.access_token
			})
		return accessToken
	}

	const getUserRoles = async (
		accessToken: string,
		user: User
	): Promise<string[]> => {
		let roles: string[] = []
		await axios
			.get(`https://oppi-chat.eu.auth0.com/api/v2/users/${user.sub}/roles`, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			})
			.then((res) => {
				roles = res.data.map((role: Role) => {
					return role.name
				})
			})
		return roles
	}

	return { getAccessToken, getUserRoles }
}
