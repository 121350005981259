import { MessageSender } from '../enums/MessageSender'
import { MessageState } from '../enums/MessageState'

export type Message = {
	audio?: Blob
	text?: string
	sender: MessageSender
	state: MessageState
}

export const introMessage: Message = {
	text: 'Welcome to OppiAI. I am your chat learning management assistant. What can I help you with?',
	sender: MessageSender.BOT,
	state: MessageState.SENT,
}
